<template>
  <div class="KCmodule">
    <Header title="知识产权" index="首页" titleOne="科创模块" beforeTitle="知识产权" />
    <div class="content">
      <el-tabs v-model="activeName" type="card" @tab-click="rzClick">
        <el-tab-pane label="专利列表" name="first">
          <div class="topUsers">
            <div>
              <el-select
                clearable
                v-model="patentParams.patent_type"
                placeholder="请选择专利类型"
                @change="changePatentType"
              >
                <el-option label="发明专利" value="1">发明专利</el-option>
                <el-option label="实用新型" value="2">实用新型</el-option>
                <el-option label="外观设计" value="3">外观设计</el-option>
                <el-option label="植物新品种" value="4">植物新品种</el-option>
                <el-option label="国家新药" value="5">国家新药</el-option>
                <el-option label="集成电路布图设计专有权" value="6">集成电路布图设计专有权</el-option>
                <el-option label="国家级农作物品种" value="7">国家级农作物品种</el-option>
                <el-option label="国家一级中药保护品种" value="8">国家一级中药保护品种</el-option>
                <el-option label="其中：国防专利" value="9">其中：国防专利</el-option>
              </el-select>
              <el-select
                clearable
                v-model="patentParams.status"
                placeholder="请选择专利状态"
                @change="changeStatus"
              >
                <el-option
                  v-for="item in selectList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <el-input
                v-model="patentParams.company_name"
                placeholder="请输入企业名称关键字"
                @keyup.enter.native="handleSearch()"
              ></el-input>
              <el-input
                v-model="patentParams.patent_name"
                placeholder="请输入申请号/专利名称"
                @keyup.enter.native="handleSearch()"
              ></el-input>
              <el-button class="search" @click="handleSearch()">查询</el-button>
            </div>
            <div style="display: flex;">
              <el-button
                v-if="privilige_list.patent.includes('upload')"
                class="upload"
                @click="$router.push('/patent/quickUpload')"
              >附件快传</el-button>
              <el-button
                v-if="privilige_list.patent.includes('export')"
                @click="goExportPatent()"
                class="import"
              >全部导出</el-button>
              <el-button
                v-if="privilige_list.patent.includes('add')"
                class="add"
                @click="addPatent('')"
              >新增</el-button>
            </div>
          </div>
          <el-table
            border
            v-loading="loading"
            element-loading-text="正在加载中···"
            :data="patent.data"
            style="width: 100%"
            v-if="privilige_list.patent.includes('list')"
          >
            <el-table-column prop="company_name" label="企业名称" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                {{
                scope.row.company_name ? scope.row.company_name : "-"
                }}
              </template>
            </el-table-column>
            <el-table-column prop="area" label="所属区域" :show-overflow-tooltip="true">
              <template slot-scope="scope">{{ scope.row.area ? scope.row.area : "-" }}</template>
            </el-table-column>
            <el-table-column prop="patent_name" label="专利名称" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                {{
                scope.row.patent_name ? scope.row.patent_name : "-"
                }}
              </template>
            </el-table-column>
            <el-table-column prop="patent_type" label="专利类型" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <span v-if="scope.row.patent_type == 0">-</span>
                <span v-if="scope.row.patent_type == 1">发明专利</span>
                <span v-if="scope.row.patent_type == 2">实用新型</span>
                <span v-if="scope.row.patent_type == 3">外观设计</span>
                <span v-if="scope.row.patent_type == 4">植物新品种</span>
                <span v-if="scope.row.patent_type == 5">国家新药</span>
                <span v-if="scope.row.patent_type == 6">集成电路布图设计专有权</span>
                <span v-if="scope.row.patent_type == 7">国家级农作物品种</span>
                <span v-if="scope.row.patent_type == 8">国家一级中药保护品种</span>
                <span v-if="scope.row.patent_type == 9">其中：国防专利</span>
              </template>
            </el-table-column>
            <el-table-column prop="app_date" label="申请日期">
              <template slot-scope="scope">{{ scope.row.app_date ? scope.row.app_date : "-" }}</template>
            </el-table-column>
            <el-table-column prop="status" label="状态" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <span v-if="scope.row.status == 1">新申请</span>
                <span v-if="scope.row.status == 2">初审合格</span>
                <span v-if="scope.row.status == 3">发明公布</span>
                <span v-if="scope.row.status == 4">发明实审</span>
                <span v-if="scope.row.status == 5">授权</span>
                <span v-if="scope.row.status == 6">驳回</span>
                <span v-if="scope.row.status == 7">撤回</span>
              </template>
            </el-table-column>
            <el-table-column prop="business_type" label="业务类型" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <span v-if="scope.row.business_type == 1">新申请</span>
                <span v-if="scope.row.business_type == 2">托管</span>
                <span v-if="scope.row.business_type == 3">变更</span>
                <span v-if="scope.row.business_type == 4">驳回重新提交</span>
                <span v-if="scope.row.business_type == 5">非正常撤回</span>
              </template>
            </el-table-column>
            <el-table-column prop="engineer" label="工程师" :show-overflow-tooltip="true">
              <template slot-scope="scope">{{ scope.row.engineer ? scope.row.engineer : "-" }}</template>
            </el-table-column>
            <el-table-column prop="process" label="流程人" :show-overflow-tooltip="true">
              <template slot-scope="scope">{{ scope.row.process ? scope.row.process : "-" }}</template>
            </el-table-column>
            <el-table-column prop="certificate_date" label="授权日期" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                {{
                scope.row.certificate_date ? scope.row.certificate_date : "-"
                }}
              </template>
            </el-table-column>
            <el-table-column prop="last_pay_time" label="最后缴费" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                {{
                scope.row.last_pay_time ? scope.row.last_pay_time : "-"
                }}
              </template>
            </el-table-column>
            <el-table-column prop="operation" label="操作" width="270px">
              <template slot-scope="scope">
                <el-button
                  v-if="privilige_list.patent.includes('edit')"
                  class="edit"
                  size="mini"
                  @click="addPatent(scope.row.id)"
                >编辑</el-button>
                <el-button
                  v-if="privilige_list.patent.includes('fee')"
                  class="edit"
                  size="mini"
                  @click="handleCost(scope.row.id)"
                >费用</el-button>
                <el-button
                  v-if="privilige_list.patent.includes('file')"
                  class="edit"
                  size="mini"
                  @click="UploadFile(scope.row.id)"
                >附件</el-button>
                <el-button
                  v-if="privilige_list.patent.includes('delete')"
                  class="del"
                  size="mini"
                  @click="handleDelete(scope.row.id)"
                >删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="block" v-if="patent.total > 0">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="patentParams.page"
              :page-sizes="[2, 10, 20, 40]"
              :page-size="patentParams.limit"
              layout="sizes, prev, pager, next,total,jumper"
              :total="patent.total"
            ></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="软著列表" name="second">
          <div class="topUsers">
            <div>
              <el-select
                clearable
                v-model="softParams.status"
                placeholder="请选择授权状态"
                @change="getsoftType()"
              >
                <el-option label="已授权" :value="1"></el-option>
                <el-option label="未受理" :value="2"></el-option>
                <el-option label="受理中" :value="3"></el-option>
                <el-option label="补正" :value="4"></el-option>
              </el-select>
              <el-input
                v-model="softParams.company_name"
                placeholder="请输入企业名称关键字"
                @keyup.enter.native="SearchSoft()"
              ></el-input>
              <el-input
                v-model="softParams.soft_name"
                placeholder="请输入登记号/软著名称"
                @keyup.enter.native="SearchSoft()"
              ></el-input>
              <el-button class="search" @click="SearchSoft()">查询</el-button>
            </div>
            <div class="box_r">
              <el-button
                :disabled="isdis"
                @click="softDelBatch()"
                :class="idArr.length > 0 ? 'delbnt':'import'"
              >批量删除</el-button>
              <el-button @click="softUpload()" class="import">附件快传</el-button>
              <el-button @click="download()" class="import">附件下载</el-button>
              <el-button
                v-if="privilige_list.soft.includes('export')"
                @click="goExportSoft()"
                class="import"
              >全部导出</el-button>
              <el-button
                v-if="privilige_list.soft.includes('add')"
                class="add"
                @click="addSoft('')"
              >新增</el-button>
            </div>
          </div>
          <el-table
            v-loading="softloading"
            element-loading-text="正在加载中···"
            :data="soft.data"
            style="width: 100%"
            v-if="privilige_list.soft.includes('list')"
            @select="handleSelect"
            @select-all="handleSelectAll"
          >
            <el-table-column label="全选" type="selection"></el-table-column>
            <el-table-column prop="company_name" label="企业名称" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                {{
                scope.row.company_name ? scope.row.company_name : "-"
                }}
              </template>
            </el-table-column>
            <el-table-column prop="area" label="所属区域" :show-overflow-tooltip="true">
              <template slot-scope="scope">{{ scope.row.area ? scope.row.area : "-" }}</template>
            </el-table-column>
            <el-table-column prop="soft_name" label="软件名称" :show-overflow-tooltip="true">
              <template slot-scope="scope">{{ scope.row.soft_name ? scope.row.soft_name : "-" }}</template>
            </el-table-column>
            <el-table-column prop="register_number" label="登记号" width="150">
              <template slot-scope="scope">
                {{
                scope.row.register_number ? scope.row.register_number : "-"
                }}
              </template>
            </el-table-column>
            <el-table-column prop="publish_time" label="开发完成日期" width="150">
              <template slot-scope="scope">
                {{
                scope.row.publish_time ? scope.row.publish_time : "-"
                }}
              </template>
            </el-table-column>
            <el-table-column prop="approval_time" label="授权下证日期" width="150">
              <template slot-scope="scope">
                {{
                scope.row.approval_time ? scope.row.approval_time : "-"
                }}
              </template>
            </el-table-column>
            <el-table-column prop="status" label="状态" width="80">
              <template slot-scope="scope">
                <span style="color: #1EC300;" class="state disable" v-if="scope.row.status == 1">已授权</span>
                <span style="color: #CBCFE3;" class="state enable" v-if="scope.row.status == 2">未受理</span>
                <span style="color: #2C50FF;" class="state enable" v-if="scope.row.status == 3">受理中</span>
                <span style="color: #FF0F0F;" class="state enable" v-if="scope.row.status == 4">补正</span>
              </template>
            </el-table-column>
            <el-table-column prop="operation" label="操作" width="210px">
              <template slot-scope="scope">
                <el-button
                  v-if="privilige_list.soft.includes('edit')"
                  class="edit"
                  size="mini"
                  @click="addSoft(scope.row.id)"
                >编辑</el-button>
                <el-button
                  v-if="privilige_list.soft.includes('file') && scope.row.file_status != 1"
                  class="edit"
                  size="mini"
                  @click="softWareUpload(scope.row.id)"
                >附件</el-button>
                <el-button
                  style="color: #C3C7D9 !important; font-weight: bold;"
                  v-if="scope.row.file_status == 1"
                  class="edit"
                  size="mini"
                  @click="softWareUpload(scope.row.id)"
                >已传</el-button>
                <el-button
                  v-if="privilige_list.soft.includes('delete')"
                  class="del"
                  size="mini"
                  @click="delSoft(scope.row.id)"
                >删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="block" v-if="soft.total > 0">
            <el-pagination
              @size-change="softSizeChange"
              @current-change="softCurrentChange"
              :current-page="softParams.page"
              :page-sizes="[2, 10, 20, 40]"
              :page-size="softParams.limit"
              layout="sizes, prev, pager, next,total,jumper"
              :total="soft.total"
            ></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="查新检测" name="third">
          <Check ref="getList" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import selectList from '../../utils/selectList.json'
import Check from '../../components/patentCheck/check.vue'
import { mapGetters, mapState } from "vuex";
export default {
  components: {
    Check,
  },
  inject: ["reload"],
  data () {
    return {
      selectList: selectList.patent_status,
      isdis: true,
      idArr: [],
      loading: true,
      softloading: true,
      activeName: "first",
      patentParams: {
        page: 1,
        limit: 10,
        keywords: "",
        patent_type: "",
        status: "",
        company_name: "",
        order: "id desc",
      },
      softTotal: 0,
      softParams: {
        page: 1,
        limit: 10,
        soft_name: "",
        status: "",
        company_name: "",
        order: "id desc",
      },
      privilige_list: {},
    };
  },
  activated () {
    // this.handleSearch();
    // this.SearchSoft();
    this.patentParams
    this.$store.dispatch("getPatentList", this.patentParams);
    this.softParams
    this.$store.dispatch("getSoftList", this.softParams);
    console.log(this.patentParams, 'patentParams')
  },
  computed: {
    ...mapState(["patent", "soft"]),
  },
  created () {
    this.$store.dispatch("getPatentList", this.patentParams);
    let arr = localStorage.getItem('privilige')
    this.privilige_list = JSON.parse(arr)
  },
  watch: {
    idArr (value, oldvalue) {
      if (value.length > 0) {
        this.isdis = false
      } else {
        this.isdis = true
      }
    },
    deep: true,
  },
  methods: {
    // 全选
    handleSelectAll (selection) {
      this.idArr = selection.map(item => item.id)
    },
    handleSelect (selection, row) {
      this.idArr = selection.map(item => item.id)
    },
    // 软著列表批量删除
    softDelBatch () {
      this.$confirm("确定删除选中项目数据，删除后无法恢复！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          this.axios.delete("/api/soft/delete_batch", {
            params: {
              ids: this.idArr,
            },
          }).then((a) => {
            this.$message.success(a.message)
            this.$store.dispatch("getSoftList", this.softParams);
          }).catch((b) => {
            this.$message.error(b.message)
          });
        })
        .catch(() => {
          this.$message.info('已取消删除')
        });
    },
    // 软著附件快传
    softUpload () {
      this.$router.push('/soft/upload')
    },
    // 软著批量下载
    download () {
      this.$router.push('/soft/download')
    },
    goExportSoft () {
      this.$router.push({
        path: "/data/data_export/go_export",
        query: { item: "soft", data: { ...this.softParams } },
      });
    },
    goExportPatent () {
      this.$router.push({
        path: "/data/data_export/go_export",
        query: { item: "patent", data: { ...this.patentParams } },
      });
    },
    rzClick (tab) {
      if (tab.name == "first") {
        this.$store.dispatch("getPatentList", this.patentParams);
      }
      if (tab.name == "second") {
        this.$store.dispatch("getSoftList", this.softParams);
      }
      if (tab.name == 'third') {
        // 调用子组件获取列表方法
        this.$refs.getList.getCheckList()
      }
    },
    handleSizeChange (val) {
      this.patentParams.limit = val;
      this.$store.dispatch("getPatentList", this.patentParams);
    },
    handleCurrentChange (val) {
      this.patentParams.page = val;
      this.$store.dispatch("getPatentList", { ...this.patentParams, keywords: '' });
    },
    softSizeChange (val) {
      this.softParams.limit = val;
      this.$store.dispatch("getSoftList", this.softParams);
    },
    softCurrentChange (val) {
      this.softParams.page = val;
      this.$store.dispatch("getSoftList", { ...this.softParams, keywords: '' });
    },
    SearchSoft () {
      this.$store.dispatch("getSoftList", { ...this.softParams, page: '', limit: '' });
      this.softParams.page = 1
    },
    getsoftType () {
      this.$store.dispatch("getSoftList", this.softParams);
    },
    addSoft (id) {
      this.$router.push("/patent/rzadd?id=" + id);
    },
    delSoft (id) {
      this.$confirm("确定删除此项目数据，删除后无法恢复！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          this.axios.delete("/api/soft/delete", {
            params: {
              id: id,
            },
          }).then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            // this.reload();
            this.$store.dispatch("getSoftList", this.softParams);
          });
        })
        .catch((res) => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    changePatentType () {
      this.$store.dispatch("getPatentList", this.patentParams);
    },
    changeStatus () {
      this.$store.dispatch("getPatentList", this.patentParams);
    },
    handleSearch () {
      this.$store.dispatch("getPatentList", { ...this.patentParams, page: '', limit: '' })
      this.patentParams.page = 1;
    },
    addPatent (id) {
      console.log({ id });
      this.$router.push("/patent/add?id=" + id);
    },
    UploadFile (id) {
      this.$router.push("/patent/annex?id=" + id);
    },
    softWareUpload (id) {
      this.$router.push({ path: "/soft/annex", query: { id: id } });
    },
    handleCost (id) {
      this.$router.push("/patent/checkcost?id=" + id);
    },
    handleDelete (id) {
      this.$confirm("确定删除此项目数据，删除后无法恢复！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          this.axios.delete("/api/patent/delete", {
            params: {
              id: id,
            },
          }).then((res) => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.$store.dispatch("getPatentList", this.patentParams);
          });
        })
        .catch((res) => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
  beforeUpdate () {
    if (this.patent.data) {
      this.loading = false;
    }
    if (this.soft.data) {
      this.softloading = false;
    }
  },
};
</script>

<style scoped="scoped">
.box_r {
  display: flex;
}
.KCmodule .import {
  float: right;
  background: #edeef4;
  color: #909399;
  border: none;
}

.KCmodule .upload {
  float: right;
  background: #edeef4;
  color: #909399;
  border: none;
}
::v-deep .el-tabs__content {
  top: 20px !important;
}
::v-deep .topUsers .el-input {
  width: 224px !important;
}
div/deep/.el-table__row:hover .cell .el-button {
  background: #fff !important;
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.16);
}
.delbnt {
  background: #ff4343;
  color: #fff;
}
</style>
