<template>
  <div>
    <div class="topUsers">
      <div>
        <el-input
          v-model="checkParams.company_name"
          @keyup.enter.native="searchCheck()"
          placeholder="请输入委托人名称关键字"
        ></el-input>
        <el-input
          v-model="checkParams.project_name"
          @keyup.enter.native="searchCheck()"
          placeholder="请输入项目名称关键字"
        ></el-input>
        <el-input
          v-model="checkParams.organization"
          @keyup.enter.native="searchCheck()"
          placeholder="请输入机构关键字"
        ></el-input>
        <el-button type="button" class="search" @click="searchCheck()">查询</el-button>
      </div>
      <div>
        <el-button
          type="button"
          :disabled="isdis"
          @click="novDelBatch()"
          :class="idArr.length > 0 ? 'delbnt':'import'"
        >批量删除</el-button>
        <el-button v-if="privilige_list.novelty.includes('add')" class="add" @click="addCheck()">新增</el-button>
      </div>
    </div>
    <el-table
      v-loading="checkLoading"
      element-loading-text="正在加载中···"
      :data="checkList"
      style="width: 100%"
      v-if="privilige_list.novelty.includes('list')"
      @select="handleSelect"
      @select-all="handleSelectAll"
    >
      <el-table-column label="全选" type="selection"></el-table-column>
      <el-table-column prop="company_name" label="委托人（合作客户）" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{
          scope.row.company_name ? scope.row.company_name : "-"
          }}
        </template>
      </el-table-column>
      <el-table-column prop="name" label="项目名称" :show-overflow-tooltip="true">
        <template slot-scope="scope">{{ scope.row.name ? scope.row.name : "-" }}</template>
      </el-table-column>
      <el-table-column prop="organization" label="机构" :show-overflow-tooltip="true">
        <template slot-scope="scope">{{ scope.row.organization ? scope.row.organization : "-" }}</template>
      </el-table-column>
      <el-table-column prop="entrust_time" label="委托日期">
        <template
          slot-scope="scope"
        >{{ (scope.row.entrust_time? scope.row.entrust_time : "-") | fmtdate}}</template>
      </el-table-column>
      <el-table-column prop="deliver_time" label="交付日期">
        <template slot-scope="scope">
          {{
          scope.row.deliver_time ? scope.row.deliver_time : "-" | fmtdate
          }}
        </template>
      </el-table-column>
      <el-table-column prop="report_no" label="报告编号">
        <template slot-scope="scope">
          {{
          scope.row.report_no ? scope.row.report_no : "-"
          }}
        </template>
      </el-table-column>
      <el-table-column prop="type" label="类型">
        <template slot-scope="scope">
          <div v-if="!scope.row.type">-</div>
          <div v-if="scope.row.type == 1">查新</div>
          <div v-if="scope.row.type == 2">检测</div>
        </template>
      </el-table-column>
      <el-table-column prop="operation" label="操作" width="210px">
        <template slot-scope="scope">
          <el-button
            v-if="privilige_list.novelty.includes('edit')"
            class="edit"
            size="mini"
            @click="addCheck(scope.row.id)"
          >编辑</el-button>
          <el-button
            v-if="privilige_list.novelty.includes('file') && scope.row.file_status != 1"
            class="edit"
            size="mini"
            @click="uploadCheck(scope.row)"
          >附件</el-button>
          <el-button
            style="color: #C3C7D9 !important; font-weight: bold;"
            v-if="scope.row.file_status == 1"
            class="edit"
            size="mini"
            @click="uploadCheck(scope.row)"
          >已传</el-button>
          <el-button
            v-if="privilige_list.novelty.includes('delete')"
            class="del"
            size="mini"
            @click="delCheck(scope.row.id)"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
        @size-change="checkSizeChange"
        @current-change="checkCurrentChange"
        :current-page="checkParams.page"
        :page-sizes="[2, 10, 20, 40]"
        :page-size="checkParams.limit"
        layout="sizes, prev, pager, next,total,jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isdis: true,
      idArr: [],
      total: 0,
      checkList: [],//查新检测列表
      checkParams: {
        keywords: '',
        page: 1,
        limit: 10,
        company_name: '',
        project_name: '',
      },
      checkLoading: false,
      privilige_list: {},
    }
  },
  created () {
    this.getCheckList()
    let arr = localStorage.getItem('privilige')
    this.privilige_list = JSON.parse(arr)
  },
  activated () {
    // this.searchCheck()
    this.getCheckList()
    this.checkParams
    console.log(this.checkParams, 'checkParams')
  },
  watch: {
    idArr (value, oldvalue) {
      console.log(value, 789)
      if (value.length > 0) {
        this.isdis = false
      } else {
        this.isdis = true
      }
    },
    deep: true,
  },
  methods: {
    // 全选
    handleSelectAll (selection) {
      this.idArr = selection.map(item => item.id)
    },
    handleSelect (selection, row) {
      this.idArr = selection.map(item => item.id)
    },
    // 软著列表批量删除
    novDelBatch () {
      this.$confirm("确定删除选中项目数据，删除后无法恢复！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          this.axios.delete("/api/novelty/delete_batch", {
            params: {
              ids: this.idArr,
            },
          }).then((a) => {
            this.$message.success(a.message)
            this.getCheckList()
          }).catch((b) => {
            this.$message.error(b.message)
          });
        })
        .catch(() => {
          this.$message.info('已取消删除')
        });
    },
    //查新检测列表获取
    getCheckList () {
      this.axios.get('/api/novelty/list', { params: { ...this.checkParams } }).then((res) => {
        console.log(res, '检测列表')
        this.checkList = res.data.data
        this.total = res.data.total
      })
    },
    checkSizeChange (val) {
      this.checkParams.limit = val
      this.getCheckList()
    },
    checkCurrentChange (val) {
      this.checkParams.page = val
      this.getCheckList()
    },
    searchCheck () {
      // if (this.checkParams.company_name == '' && this.checkParams.project_name == '') {
      //   this.axios.get('/api/novelty/list', {
      //     params: { ...this.checkParams, page: '', limit: '' }
      //   }).then((res) => {
      //     this.checkParams.page = 1
      //     this.total = res.data.total
      //     this.checkList = res.data.data
      //   })
      // } else {
      this.axios.get('/api/novelty/list', {
        params: { ...this.checkParams, page: '', limit: '' }
      }).then((res) => {
        this.checkParams.page = 1
        this.total = res.data.total
        this.checkList = res.data.data
      })
      // }
    },
    addCheck (id) {
      this.$router.push({ path: '/patent/check/add', query: { id: id } })
    },
    uploadCheck (row) {
      this.$router.push({ path: '/patent/check/upload', query: { data: row } })
    },
    delCheck (id) {
      this.$confirm("确定删除此项目数据，删除后无法恢复！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          this.axios.delete("/api/novelty/delete", {
            params: {
              id: id,
            },
          }).then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getCheckList()
          });
        })
        .catch((res) => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  }

}
</script>

<style scoped>
div/deep/.import {
  border: #edeef4;
  background: #edeef4;
  color: #909399;
}
div/deep/.import:hover {
  background: #edeef4;
  color: #909399;
}
.delbnt {
  background: #ff4343;
  color: #fff;
}
</style>